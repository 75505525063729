import axios from 'axios'
import i18n from '@/i18n';

axios.defaults.baseURL = process.env.VUE_APP_API

export default {
  fetchWidget: ({ id, skiStationId, weatherStationId }) => {
    return axios.get(`/api/external/v1/widgets/${id}`, {
      params: {
        skiStationId,
        weatherStationId
      },
      headers: {
        'Accept-Language': i18n.global.locale
      }
    })
  }
}
