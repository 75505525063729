import { createApp } from 'vue'
import App from './App.vue'
import vClickOutside from 'click-outside-vue3'
import store from './store'
import i18n from './i18n'
import FloatingVue, { VTooltip } from 'floating-vue'

import '@/assets/scss/style.scss'
import 'floating-vue/dist/style.css'

FloatingVue.options.distance = 9
FloatingVue.options.disposeTimeout = 1000000
FloatingVue.options.themes.tooltip.delay.show = 0

const app = createApp(App)

app.use(store)
app.use(i18n)
app.use(vClickOutside)

app.directive('tooltip', VTooltip)

app.mount('#app')
