<template>
  <div id="frame" data-radius="true" data-shadow="true">
    <widget-basic v-if="widget && widget.type === 'WEB'" ref="widget" :default-lang="lang" />
    <!-- <widget-signage v-if="widget && widget.type === 'SIGNAGE'" ref="widget" /> -->
    <div v-if="status === 'ERROR'" class="frame-error">
      <h3>{{ $t('errors.fetching.title') }}</h3>
      <p>{{ $t('errors.fetching.advice') }}</p>
    </div>
    <loader v-if="status === 'LOADING'" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'App',
  components: {
    WidgetBasic: defineAsyncComponent(() => import('./widgets/WidgetBasic/Index')),
    // WidgetSignage: defineAsyncComponent(() => import('./widgets/WidgetSignage/Index')),
    Loader: defineAsyncComponent(() => import('@/components/Loader'))
  },
  data() {
    return {
      widgetId: undefined,
      selectedSkiStationId: undefined,
      mode: undefined,
      lang: undefined
    }
  },
  computed: {
    ...mapState(['currentStationIndex', 'widget', 'status'])
  },
  watch: {
    currentStationIndex(value) {
      this.changeStation(this.widget.availableStations[value] ? this.widget.availableStations[value].id : this.widget.availableStations[0])
    }
  },
  created() {
    console.log('[TSS] v1.01')
    const params = new URL(window.location.href).searchParams
    this.widgetId = params.get('id') || '5fd38d2ec8d2ae255697440d'
    this.selectedSkiStationId = params.get('selectedSkiStationId')
    this.mode = params.get('mode') || 'ALL'
    this.lang = params.get('lang') || 'pl'

    if (this.lang.includes('-')) {
      this.lang = this.lang.split('-')[0]
    }
    this.$i18n.locale = this.lang
    this.showLangsPopup = false
    dayjs.locale(this.lang)

    if (this.widgetId) this.getWidget()

    window.addEventListener('message', (e) => {
      if (e.data.type === 'tss:set-widget') {
        this.SET_WIDGET(e.data.payload)
        this.setStyles()
        this.SET_STATUS('SUCCESS')
      }
    })
  },
  methods: {
    ...mapMutations(['SET_WIDGET', 'SET_STATUS', 'SET_CURRENT_STATION_BY_ID', 'SET_WEATHER_STATION', 'SET_MODE']),
    ...mapActions(['fetchWidget']),
    getWidget() {
      this.fetchWidget({ id: this.widgetId, selectedSkiStationId: this.selectedSkiStationId, mode: this.mode }).then(() => {
        this.setStyles()
        setTimeout(() => {
          if (this.widget.type !== 'SIGNAGE') this.fetchWidget()
        }, 5 * 60 * 1000)
      })
    },
    setStyles() {
      document.documentElement.style.setProperty('--color-primary', this.widget.colorMain)
      document.documentElement.setAttribute('data-theme', this.widget.theme)
      document.documentElement.setAttribute('data-rounded-corners', this.widget.roundedCorners)
      document.documentElement.setAttribute('data-transparent-bg', this.widget.transparentBackground)
      if (this.widget.type === 'SIGNAGE') document.documentElement.style.fontSize = '1.8vh'
    },
    changeStation(stationId) {
      this.SET_STATUS('LOADING')
      this.SET_WEATHER_STATION(null)
      this.SET_CURRENT_STATION_BY_ID(stationId)

      this.fetchWidget({ id: this.widgetId, selectedSkiStationId: this.selectedSkiStationId, mode: this.mode }).then(() => {
        if (this.widget.type === 'SIGNAGE') this.$refs.widget.$emit('update-timer')
      })
    }
  }
}
</script>

<style lang="scss">
#frame {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  @include remove-scrollbar;

  > .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.frame-error {
  text-align: center;
  padding: 3rem 0;
}
</style>
